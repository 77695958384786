<template>
  <v-container fluid class="pa-0">
    <v-card flat tile max-width="1200" class="mx-auto d-flex flex-column">
      <div class="mx-4 d-flex align-center">
        <p class="title mb-0" style="color:rgba(39,135,255,1);">船名：{{this.$route.query.shipname}}</p>
        <v-dialog v-model="newCabin" class="ml-auto">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="ml-auto my-6" tile dark depressed color="#2787FF">新增船舶</v-btn>
          </template>

          <!-- :info="newWorkList"
          :shipList="shipSelectList"-->
          <!-- <dialogCard width="670" titleTxt="新增船舶" @cancel="newCabin = false" @submit="newTanker">
            <template v-slot:info>
              <v-form v-model="newWorkValid">
                <v-container fluid class="pa-0 px-12">
                  <div class="d-flex align-center">
                    <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                    <v-subheader class="title" style="color:rgba(39,135,255,1);">船舶信息（必填项）</v-subheader>
                  </div>
                  <v-row align="center">
                    <v-col md="6" sm="12" cols="12">
                      <div class="d-flex justify-space-between align-center">
                        <label for class="text-left">船舶公司</label>
                      </div>
                      <v-select
                        :rules="rules"
                        v-model="companyId"
                        :items="companiesList"
                        item-value="id"
                        item-text="firmname"
                        background-color="#EAF4FE"
                        placeholder="请选择公司"
                        hide-details
                        flat
                        solo
                      ></v-select>
                    </v-col>
                    <v-col md="6" sm="12" cols="12">
                      <div class="d-flex justify-space-between align-center">
                        <label for class="text-left">船名</label>
                      </div>
                      <v-text-field
                        :rules="rules"
                        v-model="shipName"
                        placeholder="请输入船名"
                        background-color="#EAF4FE"
                        hide-details
                        flat
                        solo
                      ></v-text-field>
                    </v-col>
                    <v-col md="6" sm="12" cols="12">
                      <div class="d-flex justify-space-between align-center">
                        <label for class="text-left">膨胀倍数</label>
                      </div>
                      <v-text-field
                        :rules="rules"
                        v-model="expansionFactor"
                        placeholder="请输入膨胀倍数"
                        background-color="#EAF4FE"
                        hide-details
                        flat
                        solo
                      >
                        <template v-slot:append-outer>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                            </template>
                            <v-img
                              src="https://www.ciplat.com/tpl/default/Index/Public/image/tong.png"
                            ></v-img>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col md="6" sm="12" cols="12">
                      <div class="d-flex justify-space-between align-center">
                        <label for class="text-left">舱总数</label>
                      </div>
                      <v-text-field
                        :rules="rules"
                        v-model="cabinsNumber"
                        placeholder="请输入舱总数"
                        background-color="#EAF4FE"
                        hide-details
                        flat
                        solo
                      >
                        <template v-slot:append-outer>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                            </template>
                            船舶总共有多少舱
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <div class="d-flex align-center">
                    <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                    <v-subheader class="title" style="color:rgba(39,135,255,1);">舱容表信息</v-subheader>
                  </div>
                  <v-row align="center">
                    <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                      <label for class="text-left">纵横倾修正表</label>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                        </template>
                        请查阅检定证书目录确认是否有纵倾、横倾修正表
                      </v-tooltip>
                      <v-switch
                        class="mt-0 pt-0"
                        v-model="tiltCorrectionTable"
                        hide-details
                        :label="tiltCorrectionTable?'包含':'未包含'"
                      ></v-switch>
                    </v-col>
                    <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                      <label for class="text-left">底量测量孔</label>
                      <v-switch
                        class="mt-0 pt-0"
                        v-model="bottomMasuringHole"
                        hide-details
                        :label="bottomMasuringHole?'包含':'未包含'"
                      ></v-switch>
                    </v-col>
                    <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                      <label for class="text-left">舱容表有效期</label>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                        </template>
                        查看有效文案底部有效期
                      </v-tooltip>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="date"
                            solo
                            hide-details
                            flat
                            prepend-inner-icon="mdi-calendar-range-outline"
                            readonly
                            v-on="on"
                            style="width:100px;"
                          ></v-text-field>
                        </template>
                        <v-date-picker locale="zh-cn" v-model="date" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                          <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                      <label for class="text-left">管线容量</label>
                      <v-switch
                        class="mt-0 pt-0"
                        v-model="pipelineCapacity"
                        hide-details
                        :label="pipelineCapacity?'包含':'未包含'"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </template>
          </dialogCard>-->
        </v-dialog>
      </div>

      <!-- 修改船舱信息 -->
      <v-dialog v-model="modifyCabinWork" class="ml-auto mx-4">
        <dialogCard
          width="670"
          titleTxt="修改船舱信息"
          @cancel="modifyCabinWork = false"
          @submit="submitModifyCabin"
        >
          <!-- @submit="modifyTanker" -->
          <template v-slot:info>
            <v-form v-model="modifyCabinValid">
              <v-container fluid class="pa-0 px-12">
                <div class="d-flex align-center">
                  <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                  <v-subheader class="title" style="color:rgba(39,135,255,1);">基本信息</v-subheader>
                </div>
                <v-row align="center">
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">舱号</label>
                    </div>
                    <v-text-field
                      :rules="rules"
                      v-model="cabin.cabinname"
                      placeholder="请输入舱号"
                      background-color="#EAF4FE"
                      hide-details
                      flat
                      solo
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">管线容量</label>
                    </div>
                    <v-text-field
                      :rules="rules"
                      v-model="cabin.pipe_line"
                      placeholder="请输入管线容量"
                      background-color="#EAF4FE"
                      hide-details
                      flat
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div class="d-flex align-center">
                  <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                  <v-subheader class="title" style="color:rgba(39,135,255,1);">容量表信息</v-subheader>
                </div>
                <v-row align="center">
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">基准高度</label>
                    </div>
                    <v-text-field
                      :rules="rules"
                      v-model="cabin.altitudeheight"
                      placeholder="请输入基准高度"
                      background-color="#EAF4FE"
                      hide-details
                      flat
                      solo
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">底量</label>
                    </div>
                    <v-text-field
                      :rules="rules"
                      v-model="cabin.bottom_volume"
                      placeholder="请输入底量"
                      background-color="#EAF4FE"
                      hide-details
                      flat
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div v-if="is_diliang">
                  <div class="d-flex align-center">
                    <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                    <v-subheader class="title" style="color:rgba(39,135,255,1);">底量表信息</v-subheader>
                  </div>
                  <v-row align="center">
                    <v-col md="6" sm="12" cols="12">
                      <div class="d-flex justify-space-between align-center">
                        <label for class="text-left">基准高度</label>
                      </div>
                      <v-text-field
                        :rules="rules"
                        v-model="cabin.dialtitudeheight"
                        placeholder="请输入基准高度"
                        background-color="#EAF4FE"
                        hide-details
                        flat
                        solo
                      ></v-text-field>
                    </v-col>
                    <v-col md="6" sm="12" cols="12">
                      <div class="d-flex justify-space-between align-center">
                        <label for class="text-left">底量</label>
                      </div>
                      <v-text-field
                        :rules="rules"
                        v-model="cabin.bottom_volume_di"
                        placeholder="请输入底量"
                        background-color="#EAF4FE"
                        hide-details
                        flat
                        solo
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <!-- <div class="d-flex align-center">
                  <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                  <v-subheader class="title" style="color:rgba(39,135,255,1);">舱容表信息</v-subheader>
                </div>
                <v-row align="center">
                  <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                    <label for class="text-left">纵横倾修正表</label>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                      </template>
                      请查阅检定证书目录确认是否有纵倾、横倾修正表
                    </v-tooltip>
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="modifyWorList.tiltCorrectionTable"
                      hide-details
                      :label="modifyWorList.tiltCorrectionTable?'包含':'未包含'"
                    ></v-switch>
                  </v-col>
                  <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                    <label for class="text-left">底量测量孔</label>
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="modifyWorList.bottomMasuringHole"
                      hide-details
                      :label="modifyWorList.bottomMasuringHole?'包含':'未包含'"
                    ></v-switch>
                  </v-col>
                  <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                    <label for class="text-left">舱容表有效期</label>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                      </template>
                      查看有效文案底部有效期
                    </v-tooltip>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="modifyWorList.date"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="modifyWorList.date"
                          solo
                          hide-details
                          flat
                          prepend-inner-icon="mdi-calendar-range-outline"
                          readonly
                          v-on="on"
                          style="width:100px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="zh-cn"
                        v-model="modifyWorList.date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(modifyWorList.date)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                    <label for class="text-left">管线容量</label>
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="modifyWorList.pipelineCapacity"
                      hide-details
                      :label="modifyWorList.pipelineCapacity?'包含':'未包含'"
                    ></v-switch>
                  </v-col>
                </v-row>-->
              </v-container>
            </v-form>
          </template>
        </dialogCard>
      </v-dialog>

      <v-data-table
        :headers="headers"
        :items="rechargeRecord"
        hide-default-footer
        class="mx-4"
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="8"
        style="border:2px solid rgba(39,135,255,0.3);"
      >
        <template v-slot:item.action="{ item }">
          <!-- 修改船舶信息 -->
          <div class="d-flex">
            <v-icon small class="mr-2" @click="modifyCabin(item)">mdi-pencil</v-icon>
          </div>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="pageCount"
          max-width="500"
          outlined
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import dialogCard from "@/components/common/dialogCard";
export default {
  components: {
    dialogCard: dialogCard
  },
  data: () => ({
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: "舱号",
        align: "start",
        sortable: false,
        value: "cabinname",
        class: "head-style title"
      },
      {
        text: "容量表基准高度",
        value: "altitudeheight",
        class: "head-style title"
      },
      { text: "容量表底量", value: "bottom_volume", class: "head-style title" },
      { text: "管线容量", value: "pipe_line", class: "head-style title" },
      { text: "操作", value: "action", class: "head-style title" }
    ],
    newCabin: false,
    modifyCabinWork: false,
    modifyCabinValid: false,
    cabin: {},
    rules: [v => !!v],
    // 充值记录
    rechargeRecord: []
  }),
  created() {
    this.info();
  },
  methods: {
    info() {
      //获取用户基础信息
      const infonList = JSON.parse(localStorage.getItem("info"));
      // 获取充值记录

      this.$server
        .cabinManagement({ shipid: this.$route.query.id })
        .then(data => {
          this.rechargeRecord = data.data;
        });
    },
    modifyCabin(data) {
      this.id = data.id;
      this.$server.modifyCabin({ id: data.id }).then(data => {
        this.modifyCabinWork = true;
        this.is_diliang = data.cabin_msg.is_diliang == "1" ? true : false;
        this.cabin = data.cabin_msg.cabin_list;
      });
    },
    submitModifyCabin() {
      this.$server
        .modifyCabinWork(
          Object.assign(
            { id: this.id, shipid: this.$route.query.id },
            {
              cabinname: this.cabin.cabinname,
              pipe_line: this.cabin.pipe_line,
              altitudeheight: this.cabin.altitudeheight,
              bottom_volume: this.cabin.bottom_volume,
              dialtitudeheight: this.cabin.dialtitudeheight,
              bottom_volume_di: this.cabin.bottom_volume_di
            }
          )
        )
        .then(data => {
          if (data.code === 1) {
            this.info();
            this.modifyCabinWork = false;
            this.bus.$emit("tips", {
              show: true,
              title: "修改成功"
            });
          } else {
            this.bus.$emit("tips", {
              show: true,
              title: data.error
            });
          }
        });
    }
  }
};
</script>

<style>
</style>